import SearchBar from '../components/SearchBar';
import Stat from '../components/Stat';
import StatList from '../components/StatList';
import {TxListItem} from '../components/StatListItem';
import {api, FlexRow, formatNumber, getvw, scheme} from '../components/base';
import Body from '../containers/Body';
import React, {useCallback, useEffect, useState,} from 'react';
import styled from 'styled-components';
import {BeatLoader} from 'react-spinners';
import WalletCard from '../components/WalletCard';
import TxCard from '../components/TxCard';
import {ITx} from '../types';
import DashMonitorCard from '../components/DashMonitorCard';
import LiveTransactionChart from '../components/Charts';
import { ImConnection } from "react-icons/im";
import { SiHashicorp } from "react-icons/si";
import { GiChaingun } from "react-icons/gi";
import { TbLockSquareRoundedFilled } from "react-icons/tb";
import { GiMining } from "react-icons/gi";
import Card from '../containers/Card';

const DashMonitorSection = styled(FlexRow)`
  margin: 8px 0px;
  @media (max-width: 768px) {
    display: none;
  }
`

const getType = (hash: string) => {
  if(hash[0] === '1' || hash[0] === '3' || hash.slice(0,2) === 'bg') return 'address'
  else if (hash.slice(0,3) === '000') return 'block';
  else return 'tx';
};

const Home = () => {
  const [isFetching, setIsFetching] = useState(true);
  const [summary, setSummary] = useState<any>(null);
  const [isFetchingTx, setIsFetchingTx] = useState(true);
  const [txs, setTxs] = useState<any>(null);
  const [noResult, setNoResult] = useState(false);
  const [focusedAddress, setFocusedAddress] = useState(null);
  const [focusedTx, setFocusedTx] = useState<any|null>(null);
  const [inputText, setInputText] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [cur, setCur] = useState(0);
  const [txLength, setTxLength] = useState(10);
  const [recordsTotal, setRecordsTotal] = useState(0);

  const search = useCallback(() => {
    const t = getType(inputText);
    setIsSearching(true);
    if (t === 'address') {
      api.get(`https://wallet.bgeo.world/api/v2/app/pub/wallet/details?address=${inputText}`).then((rspn: any) => {
        let balance = -1;
        if(rspn.data.type === "success") {
          balance = rspn.data.data.balance;
        }

        api.get(`/ext/getaddress/${inputText}`)
        .then((rspn: any) => {
          if (rspn.data.address) {
            setFocusedAddress({ ...rspn.data, balance});
          }
          else {
            setNoResult(true);
          }
            setIsSearching(false);
        }).catch((e) => setNoResult(true));
      })
    } else if (t === 'tx') {
      api.get(`/ext/gettx/${inputText}`)
        .then((rspn: any) => {
          if (rspn.data.tx) {
            const rtx = rspn.data.tx;
            const _tx = [ rspn.data.blockhash, rtx.blockhash, rtx.txid, rtx.total, rtx.blockindex, rtx.timestamp ];
            setFocusedTx(_tx);
          } else {
            setNoResult(true);
          }
          setIsSearching(false);
        }).catch((e) => setNoResult(true));

    }

  }, [inputText])

  useEffect(() => {
    api.get('ext/summary')
      .then((rspn: any) => {
        setSummary(rspn.data.data[0]);
        setIsFetching(false);
      })
  }, [])

  
  useEffect(() => {
    api.get(`ext/getlasttxsajax/0?start=${cur}&length=${txLength}`)
      .then((rspn: any) => {
        if (rspn.data.data.length === 0) {
          return;
        }
        setTxs(rspn.data.data);
        setRecordsTotal(rspn.data.recordsTotal);
        setIsFetchingTx(false);
      })
  }, [cur, txLength]);

  const onClickTx = useCallback((tx: ITx) => {
    setFocusedTx(tx);
  }, [setFocusedTx])

  console.log(focusedTx);

  const renderPageButtons = () => {
    const totalPages = Math.ceil(recordsTotal / txLength);
    const currentPage = Math.floor(cur / txLength) + 1;
    const pageButtons = [];

    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        pageButtons.push(
          <button
            key={i}
            onClick={() => setCur((i - 1) * txLength)}
            className={`flex p-2 mx-1 bg-[#1E2423] rounded-md border-[#1c1f24] border-[0.07rem] ${i === currentPage ? 'bg-[#282D2B]' : ''}`}
          >
            {i}
          </button>
        );
      }
    } else {
      if (currentPage <= 4) {
        for (let i = 1; i <= 5; i++) {
          pageButtons.push(
            <button
              key={i}
              onClick={() => setCur((i - 1) * txLength)}
              className={`flex p-2 mx-1 bg-[#1E2423] rounded-md border-[#1c1f24] border-[0.07rem] ${i === currentPage ? 'bg-[#282D2B]' : ''}`}
            >
              {i}
            </button>
          );
        }
        pageButtons.push(<span key="dots1">...</span>);
        pageButtons.push(
          <button
            key={totalPages}
            onClick={() => setCur((totalPages - 1) * txLength)}
            className={`flex p-2 mx-1 bg-[#1E2423] rounded-md border-[#1c1f24] border-[0.07rem] ${totalPages === currentPage ? 'bg-[#282D2B]' : ''}`}
          >
            {totalPages}
          </button>
        );
      } else if (currentPage > totalPages - 3) {
        pageButtons.push(
          <button
            key={1}
            onClick={() => setCur(0)}
            className={`flex p-2 mx-1 bg-[#1E2423] rounded-md border-[#1c1f24] border-[0.07rem] ${1 === currentPage ? 'bg-[#282D2B]' : ''}`}
          >
            1
          </button>
        );
        pageButtons.push(<span key="dots2">...</span>);
        for (let i = totalPages - 4; i <= totalPages; i++) {
          pageButtons.push(
            <button
              key={i}
              onClick={() => setCur((i - 1) * txLength)}
              className={`flex p-2 mx-1 bg-[#1E2423] rounded-md border-[#1c1f24] border-[0.07rem] ${i === currentPage ? 'bg-[#282D2B]' : ''}`}
            >
              {i}
            </button>
          );
        }
      } else {
        pageButtons.push(
          <button
            key={1}
            onClick={() => setCur(0)}
            className={`flex p-2 mx-1 bg-[#1E2423] rounded-md border-[#1c1f24] border-[0.07rem] ${1 === currentPage ? 'bg-[#282D2B]' : ''}`}
          >
            1
          </button>
        );
        pageButtons.push(<span key="dots3">...</span>);
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          pageButtons.push(
            <button
              key={i}
              onClick={() => setCur((i - 1) * txLength)}
              className={`flex p-2 mx-1 bg-[#1E2423] rounded-md border-[#1c1f24] border-[0.07rem] ${i === currentPage ? 'bg-[#282D2B]' : ''}`}
            >
              {i}
            </button>
          );
        }
        pageButtons.push(<span key="dots4">...</span>);
        pageButtons.push(
          <button
            key={totalPages}
            onClick={() => setCur((totalPages - 1) * txLength)}
            className={`flex p-2 mx-1 bg-[#1E2423] rounded-md border-[#1c1f24] border-[0.07rem] ${totalPages === currentPage ? 'bg-[#282D2B]' : ''}`}
          >
            {totalPages}
          </button>
        );
      }
    }

    return pageButtons;
  };

  return (
    <Body>
      { false &&
        [1,2,3,4,5,6,7,8].map((idx: number) => (
          <FlexRow style={{ opacity: 0.5, padding: "0px 8px", width: getvw(1), marginRight: "16px", height: "100%", border: `1px solid ${scheme.accent}`, position: "absolute",
            top: 0, left: getvw(idx-1, 0)}} >
            <FlexRow wd={"100%"} style={{borderRight: `1px solid ${scheme.outline}`, borderLeft: `1px solid ${scheme.outline}`}}></FlexRow>
          </FlexRow>
        ))
      }
      <DashMonitorSection hg={'18vh'} >
        <DashMonitorCard icon={<ImConnection style={{ marginTop: '-2px' }}/>} title={'Connections'} value={summary?.connections} isFetching={isFetching}/>
        <DashMonitorCard icon={<GiMining />} title={'Difficulty'} value={summary?.difficulty.slice(0,6)} isFetching={isFetching} />
        <DashMonitorCard icon={<GiChaingun />} title={'Hash Rate'} value={summary?.hashrate} isFetching={isFetching} />
        <DashMonitorCard icon={<GiChaingun  />} title={'Block Count'} value={formatNumber(summary?.blockcount)} isFetching={isFetching} />
      </DashMonitorSection>
      <LiveTransactionChart />
      <FlexRow mt={'8px'}>
        <SearchBar onChange={(e: any) => setInputText(e.target.value)} onEnter={search} isSearching={isSearching}/>
      </FlexRow>
      {noResult && <p style={{ width: '100%', color: scheme.gray }} className={'p-4 text-base '}>No results for "{inputText}"</p> }
      <div style={{ display: 'none'}} className={'flex pt-3'}>
        <Stat label={'Circulating Supply'} value={461} total={578} isFetching={isFetching}/>
        <div className={'w-12'} />
        <Stat label={'Active Stake'} value={461} total={578} isFetching={isFetching}/>
      </div>
      {
        focusedAddress && <WalletCard wallet={focusedAddress} onClose={(e: any) => setFocusedAddress(null)}/>
      }
      {
        focusedTx && <TxCard tx={focusedTx} onClose={(e: any) => setFocusedTx(null)}/>
      }
      <div className={"flex mt-5 items-end"}>
        <p>Show</p>
        <select className={"flex px-2 flex-col w-fit bg-[#1E2423] rounded-md border-[#1c1f24] border-[0.07rem] appearance-none mx-1"} onChange={(e) => setTxLength(parseInt(e.target.value))}>
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
        </select>
        <p>entries</p>
      </div>
      <StatList style={{marginTop: '1.5rem'}} header={'Recent transactions'}>
        { isFetchingTx &&
            <FlexRow wd={'100%'} hg={'64px'} jc='center' ai="center">
              <BeatLoader color={scheme.accent} size={6}/>
            </FlexRow>
        }
        {
          txs?.map((tx: any) =>
            <TxListItem key={`tx-item-${tx.address}`} block={tx[0]} address={tx[2]} isFetching={isFetching} onClick={(e: any) => onClickTx(tx)}>{tx[5]}</TxListItem>)
        }
      </StatList>
      <Card style={{marginTop: '1.5rem', marginBottom: "1.5rem"}}>
        <FlexRow jc={'center'} mt={'8px'}>
          <button onClick={(e) => setCur(cur - txLength < 0 ? 0 : cur - txLength)} className={'flex p-2 mx-1 bg-[#1E2423] rounded-md border-[#1c1f24] border-[0.07rem]'}>Previous</button>
          {renderPageButtons()}
          <button onClick={(e) => setCur(cur + txLength >= recordsTotal ? cur : cur + txLength)} className={'flex p-2 mx-1 bg-[#1E2423] rounded-md border-[#1c1f24] border-[0.07rem]'}>Next</button>
        </FlexRow>
      </Card>
    </Body>
  );
}

export default Home;
