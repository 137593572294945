import SupplyPage from "./SupplyPage";
import InspectorPage from "./InspectorPage";
import Home from "./home";
import RichlistPage from "./RichlistPage";

export default [
  { name: "Cluster Stats", path: "", element: <Home /> },
  { name: "Supply", path: "supply", element: <SupplyPage /> },
  { name: "Richlist", path: "richlist", element: <RichlistPage /> },
  // { name: "Inspector", path: "/inspector", element: <InspectorPage /> }
];
