import Logo from "./Logo";
import NavigateButton from "./NavigateButton";
import { Link } from "react-router-dom";
import { FlexRow, getvw, scheme } from "./base";
import routes from '../routes';

function Header() {
  return (
    <header className={"flex bg-[#1E2423] justify-center"}>
      <div
        className={
          "flex flex-auto flex-row  max-w-screen-xl justify-between px-[4.5rem] self-center"
        }
      >
        {" "}
        <Link to={"/"} className={"flex flex-row items-center py-[1.33rem]"}>
          <Logo />
          <h4 className={"text-white"}>BGEO</h4>
          <p
            className={`text-[${scheme.accent}] text-[0.5rem] tracking-widest ml-2`}
          >
            EXPLORER (BETA)
          </p>
        </Link>
        <div className={"flex flex-row"}>
          {
            routes.map((route, index) => {
              return <NavigateButton key={index} text={route.name} link={route.path} />
            })
          }
        </div>
      </div>
    </header>
  );
}

export default Header;
